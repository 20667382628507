const COLORS = {
  white: '#ffffff',
  black: '#000000',
  lightGray: '#E5E5E5',
  heavyGray: '#787878',
  gray: '#C4C4C4',
  gray2: '#E7E7E5',
  gray3: '#828282',
  gray4: '#BDBDBD',
  gray5: '#E0E0E0',
  gray6: '#F2F2F2',

  blue: '#52BDC7',
  darkBlue: '#080B1E',

  green: '#0AB414',
  darkGreen: '#144F1D',
  lightGreen: '#DFECDA',
  successGreen: '#219653',

  red: '#EB5757',

  purple: '#A25C96',
  darkPurple: '#655594',
};

export default COLORS;
