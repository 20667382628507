
export enum NotificationType {
  PremioInstantaneo = 0,
  Sorteio = 1,
  Aviso = 2
}

export interface Notification {
  idNotification: number;
  stage_name?: string;
  game_name?: string;
  type: number | NotificationType; //0- premio instantaneo, 1- sorteio
  title: string;
  dt_event: string;
  description: string;
  has_viewed: boolean;
}