import COLORS from '@/enums/colors';

export interface DefaultThemeProps {
  colors: {
    white: string;
    black: string;
    lightGray: string;
    heavyGray: string;
    gray: string;
    gray2: string;
    gray3: string;
    gray4: string;
    gray5: string;
    gray6: string;

    blue: string;
    darkBlue: string;

    green: string;
    darkGreen: string;
    lightGreen: string;
    successGreen: string;

    red: string;

    purple: string;
    darkPurple: string;
  };

  backgroundImage: string;
}

export const defaultTheme = {
  colors: {
    ...COLORS,
  },
  backgroundImage: `var(--bg-img-url);`,
};

export const backgroundColorGreyTheme = {
  colors: {
    ...COLORS,
  },
  backgroundImage: `linear-gradient(to bottom, #E5E5E5, #E5E5E5);`,
};

export const backgroundColorPurpleTheme = {
  colors: {
    ...COLORS,
  },
  backgroundImage: `linear-gradient(to bottom, #8E26CD, #8E26CD);`,
};


export const backgroundColorGreenTheme = {
  colors: {
    ...COLORS,
  },
  backgroundImage: `linear-gradient(to bottom, #00A542, #00A542);`,
};

export const backgroundColorWatermelonTheme = {
  colors: {
    ...COLORS,
  },
  backgroundImage: `linear-gradient(to bottom, #D84654, #D84654);`,
};
