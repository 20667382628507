import {
  HStack,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import ButtonAction from '@components/ButtonAction';
import { Notification, NotificationType } from '@interfaces/Notification';
import { putNotificationViewedReq } from '@services/notifications';
import { memo, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

export default memo(PrizeModal);

interface PrizeModalProps {
  showModal: boolean;
  notifications: Notification[];
  onClickOk(): any;
}

function PrizeModal({ showModal, notifications, onClickOk }: PrizeModalProps) {
  const [showModalProsp, setShowModal] = useState(showModal);
  const [index, setIndex] = useState(0);

  const notification = notifications[index];

  async function handlePrizeButton() {
    // console.log('onClickOk',typeof(onClickOk))
    setShowModal(false);
    putNotificationViewedReq(notification.idNotification);
    if (notifications.length - 1 == index) {
      onClickOk();
    } else {
      setTimeout(() => {
        setIndex(index + 1);
        setShowModal(true);
      }, 200);
    }
  }

  const renderTitle = () => {
    if (notification.type == NotificationType.Aviso) {
      // return (<div
      //   dangerouslySetInnerHTML={{ __html: notification.title }}
      // ></div>)

      return (
        <Text fontSize={'xl'} fontWeight="bold" lineHeight={1}>
          {notification.title}
        </Text>
      );
    }
    return (
      <>
        <Text fontSize={'lg'}>Você ganhou</Text>
        <Text fontSize={'xl'} fontWeight="bold" lineHeight={1}>
          {notification.title}
        </Text>
        <Text fontSize={'lg'}>por participar!</Text>
        <Text fontSize={'md'}>
          Veja depois <i>Meus Prêmios</i>.
        </Text>
      </>
    );
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={showModalProsp}
      onClose={() => console.log('ModalClosed')}
      isCentered
      size="xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent alignItems="center" color="black" mx={5}>
        <Image
          src={notification.type == NotificationType.Aviso?"icons/atetion.png":"icons/gift.svg"}
          position="absolute"
          marginTop={-50}
          height={67}
          width={67}
        />
        <ModalHeader minWidth="100%">
          <IconButton
            aria-label="Ler Todos"
            icon={<IoCloseOutline />}
            color="#747474"
            variant="ghost"
            fontSize="25px"
            position="absolute"
            right={0}
            top={0}
            onClick={() => {
              putNotificationViewedReq('all');
              setShowModal(false);
            }}
          />
          <HStack justifyContent={'center'}>
            <Text fontSize={'md'}>
              {notification.type == NotificationType.Aviso
                ? 'Atenção'
                : 'Prêmio instantâneo'}
            </Text>
          </HStack>
        </ModalHeader>
        <ModalBody textAlign="center">
          <VStack spacing={5}>
            {notification.type == NotificationType.PremioInstantaneo && (
              <Text fontWeight="bold" fontSize={['2xl', '3xl']}>
                UHUUUU!
              </Text>
            )}
            <VStack bg="#F2F2F2" py={5} px={10} borderRadius={6} spacing={0}>
              {renderTitle()}
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter minWidth="80%">
          <VStack minWidth="100%">
            <ButtonAction onClick={() => handlePrizeButton()}>
              {notifications.length - 1 == index ? 'OK!' : 'Próximo'}
            </ButtonAction>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
