import { extendTheme } from "@chakra-ui/react"


const theme = extendTheme({
  fonts: {
    heading: `'DM Sans', sans-serif`,
    body: `'DM Sans', sans-serif`,
  },
  styles: {
    global: () => ({
      body: {
        bg:"#f7fafc",
        color: "#000"
      },
    }),
  },
})


export { theme };