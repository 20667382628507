import * as Sentry from '@sentry/nextjs';
import logger from '@logger/logger';

const ENV = process.env.VERCEL_ENV || process.env.NEXT_PUBLIC_NODE_ENV;
logger.info("ENV")
logger.info(ENV)


// if (ENV == "production" || ENV == "preview") {
//   logger.info("enabling sentry");
  
//   const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
//   logger.info("SENTRY_DSN")
//   logger.info(SENTRY_DSN)

//   Sentry.init({
//     dsn: SENTRY_DSN,
//     environment: ENV,
//     tracesSampleRate: ENV != "production" ? 0.0 : 0.1,
//     debug: ENV != "production" ? true : false,
//     tunnel: "/api/tunnel"
//   });
// }